import React, { useState } from 'react';
import styles from './styles.module.scss';

const Converter = () => {
	const [text, setText] = useState('');
	const [parsedText, setParsedText] = useState('');
	const [isValidJson, setIsValidJson] = useState(false);

	const handleTextChange = e => {
		setText(e?.target?.value);
		try {
			const data = JSON.parse(e?.target?.value);
			setIsValidJson(true);

			// console.log(data.title);
			console.log('hello', dotNotate(data));
			// data?.map(key => {
			// 	console.log({ key })
			// 	return key
			// })
			// setParsedText()
		} catch (e) {
			console.log('not valid');
			console.log(e);
			setIsValidJson(false);
		}
	};
	return (
		<div className={styles.converter}>
			{isValidJson ? '' : 'not'} valid JSON data.
			{/*{`${text}`}*/}
			{/*{!!parsedText &&*/}
			{/*	JSON.parse(parsedText).map(obj => {*/}
			{/*		console.log({ obj })*/}
			{/*		return obj*/}
			{/*	})}*/}
			<hr />
			<textarea value={text} onChange={handleTextChange} />
		</div>
	);
};
function dotNotate(obj, target, prefix) {
	(target = target || {}), (prefix = prefix || '');

	Object.keys(obj).forEach(function (key) {
		if (typeof obj[key] === 'object') {
			dotNotate(obj[key], target, prefix + key + '.');
		} else {
			return (target[prefix + key] = obj[key]);
		}
	});

	return target;
}

export default Converter;
